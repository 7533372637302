import type { FabProps } from '@mui/material'
import { Fab } from '@mui/material'
import { Upload } from '@tunasong/icons'
import type { Entity, Persisted } from '@tunasong/schemas'
import { isMobileDevice } from '../browser/detect.js'
import { useUploadFiles } from '../upload/upload-files.hook.js'
import { useRef } from 'react'

export interface UploadFabButtonProps extends Omit<FabProps, 'onClick'> {
  parent: Persisted<Entity>
}

export const UploadFabButton = (props: UploadFabButtonProps) => {
  const { sx, parent, ...restProps } = props

  const { handleChange } = useUploadFiles()

  const uploadRef = useRef<HTMLInputElement | null>(null)
  const handleClick = () => uploadRef.current?.click()

  return isMobileDevice() ? (
    <>
      <input ref={uploadRef} type="file" multiple={true} accept={'audio/*'} hidden onChange={handleChange(parent.id)} />
      <Fab
        size="small"
        color="primary"
        sx={{
          position: 'fixed',
          bottom: 64,
          right: 24,
          zIndex: 1000,
          ...sx,
        }}
        onClick={handleClick}
        {...restProps}
      >
        <Upload />
      </Fab>
    </>
  ) : null
}

import type { FieldValuesFromFieldErrors } from '@hookform/error-message'
import { ErrorMessage } from '@hookform/error-message'
import { Typography } from '@mui/material'
import type { FC } from 'react'
import { Fragment, useContext } from 'react'
import type { FieldErrors, FieldName } from 'react-hook-form'
import { FormComponentContext } from './component-provider.js'
import type { FormControlProps } from './form-control-props.js'

const ErrorLabel: FC<{ message: string }> = ({ message }) => (
  <Typography sx={{ my: 1 }} display="block" variant="caption" color="error">
    {message}
  </Typography>
)

export function FormControl(props: FormControlProps) {
  const { name, formField, errors, control, register, autoFocus } = props

  const formComponents = useContext(FormComponentContext)
  const Component = formComponents?.[formField.type]
  if (!Component) {
    return null
  }

  return (
    <Fragment key={name}>
      <Component
        sx={{ my: 1 }}
        name={name}
        autoFocus={autoFocus}
        control={control}
        register={register}
        spec={formField}
      />

      {errors ? (
        <ErrorMessage
          name={name as FieldName<FieldValuesFromFieldErrors<FieldErrors>>}
          errors={errors}
          render={ErrorLabel}
        />
      ) : null}
    </Fragment>
  )
}

export default FormControl

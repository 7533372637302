import type { GridProps, GridSize, GridSpacing } from '@mui/material'
import { Box, Grid, Typography } from '@mui/material'
import { graphHooks } from '@tunasong/graph-lib/react'
import { getImageUrl } from '@tunasong/models'
import { getPluginByType, usePlugins } from '@tunasong/plugin-lib'
import type { Entity, Persisted } from '@tunasong/schemas'
import { isImage } from '@tunasong/schemas'
import range from 'just-range'
import type { FC } from 'react'
import { useCallback } from 'react'
import { useDimensions } from '../layout/dimensions.js'
import { EntityCard } from './entity-card.js'

export interface EntityGridProps extends Omit<GridProps, 'size'> {
  className?: string
  size?: 'small' | 'medium' | 'large'
  entities: Persisted<Entity>[]
  skeletonItems?: number
  placeholder?: string
  loading?: boolean
  onOpen?(entity: Persisted<Entity>): void
}

export const EntityGrid: FC<EntityGridProps> = props => {
  const {
    onOpen,
    placeholder = 'No entities found',
    skeletonItems: providedSkeletonItems,
    entities = [],
    loading = false,
    size = 'medium',

    ...restProps
  } = props
  const plugins = usePlugins('all')
  const { isSmallOrSmaller } = useDimensions()

  const handleOpen = useCallback(
    (entity: Persisted<Entity>) => () => {
      if (!onOpen) {
        return
      }
      onOpen(entity)
    },
    [onOpen]
  )
  const { spacing, xs, sm, md, lg }: { spacing: GridSpacing; sm: GridSize; md: GridSize; lg: GridSize; xs: GridSize } =
    {
      spacing: 2,
      xs: 6,
      sm: 4,
      md: 4,
      lg: 4,
    }
  const { isTiny } = useDimensions()

  const skeletonItems = providedSkeletonItems ? providedSkeletonItems : isTiny ? 2 : 3

  const { data: imageEntities } = graphHooks.useEntitiesByEdgeByIds({
    sourceIds: entities.map(e => e.id),
    relation: 'hasImage',
  })

  /** We want to get all the entityIds hasImage relation */

  if (entities.length === 0 && !loading) {
    return (
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          ...restProps.sx,
        }}
      >
        <Typography variant="caption" sx={{ textAlign: 'center' }}>
          {placeholder}
        </Typography>
      </Box>
    )
  }

  return (
    <Grid sx={{ overflow: 'auto' }} spacing={spacing} container={true} {...restProps}>
      {/* Skeleton cards when loading  */}

      {loading
        ? range(skeletonItems).map(n => (
            <Grid size={{ xs, sm, md, lg }} key={n}>
              <EntityCard key={n} />
            </Grid>
          ))
        : entities.map((e, idx) => {
            const plugin = getPluginByType(e.type, plugins)
            const Card = plugin?.components?.Card ?? EntityCard
            const key = e.id ?? idx

            const image = imageEntities?.find(i => i.parentId === e.id)
            const imageUrl = isImage(image) ? getImageUrl(image, 'small') : undefined
            return (
              <Grid size={{ xs, sm, md, lg }} key={key}>
                <Card
                  entity={e}
                  size={size}
                  backgroundImage={imageUrl}
                  onClick={handleOpen(e)}
                  showSecondary={!isSmallOrSmaller}
                />
              </Grid>
            )
          })}
    </Grid>
  )
}

export default EntityGrid

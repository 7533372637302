import { Fab } from '@mui/material'
import type { FabProps } from '@mui/material'
import { Edit } from '@tunasong/icons'
import type { MouseEvent } from 'react'
import { isMobileDevice } from '../browser/detect.js'

export interface MobileButtonProps extends Omit<FabProps, 'onChange'> {
  edit: boolean
  onChange: (edit: boolean) => void
}

export const MobileEditButton = (props: MobileButtonProps) => {
  const { onChange, edit, sx, ...restProps } = props

  const handleEdit = (ev: MouseEvent) => {
    ev.preventDefault()
    onChange(!edit)
  }

  return isMobileDevice() ? (
    <Fab
      size="small"
      color={edit ? 'secondary' : 'primary'}
      sx={{
        position: 'fixed',
        bottom: 64,
        right: 24,
        zIndex: 1000,
        ...sx,
      }}
      {...restProps}
      onClick={handleEdit}
    >
      <Edit />
    </Fab>
  ) : null
}

import { Box, styled, useTheme } from '@mui/material'
import type { BoxProps } from '@mui/material'
import { useUserConfig } from '../config/config-service.hook.js'

const ContentPaper = styled('div', {
  shouldForwardProp: prop => prop !== 'showColumns',
})<{ showColumns?: boolean }>(({ theme, showColumns }) => ({
  display: 'block',
  /** @see https://www.smashingmagazine.com/2019/01/css-multiple-column-layout-multicol/ */
  ...(showColumns
    ? {
        columnWidth: 400,
        columnCount: 'auto',
        columnGap: '1em',
        columnRule: `1px solid ${theme.vars.palette.secondary.light}`,
        columnFill: 'balance',
      }
    : {}),

  padding: theme.spacing(1, 1, 1, 1),

  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}))

export interface ContentLayoutProps extends Omit<BoxProps, 'component'> {
  className?: string
  size: 'small' | 'medium' | 'large'
}

export const ContentLayout = (props: ContentLayoutProps) => {
  const { className, children, size = 'large', sx, ref, ...restProps } = props
  const [showColumns] = useUserConfig('showContentColumns')
  const theme = useTheme()

  return (
    <Box
      {...restProps}
      sx={{
        fontSize: size === 'small' ? '0.8rem' : size === 'medium' ? '1rem' : '1.2rem',
        flex: 1,
        overflow: 'auto',
        margin: 0,
        [theme.breakpoints.down('sm')]: {
          margin: 0,
        },
        [theme.breakpoints.up('sm')]: {
          margin: 'auto',
          width: 'min(1024px, 100%)',
          padding: theme.spacing(2, 4, 2, 4),
        },
        ...sx,
      }}
      ref={ref}
      className={className}
    >
      <ContentPaper showColumns={showColumns}>{children}</ContentPaper>
    </Box>
  )
}

export default ContentLayout

import { colors as muiColors } from '@mui/material'
import type { ColorName } from '@tunasong/schemas'

const colors = [
  'amber',
  'blue',
  'blueGrey',
  'brown',
  'cyan',
  'deepOrange',
  'deepPurple',
  'green',
  'grey',
  'indigo',
  'lightBlue',
  'lightGreen',
  'lime',
  'orange',
  'pink',
  'purple',
  'red',
  'teal',
  'yellow',
]

const excludeColors = ['grey', 'blueGrey']

export const colorNames = colors
  .filter(c => !excludeColors.includes(c))
  .sort()
  .reverse() as readonly ColorName[]

type Intensity = keyof typeof muiColors.blue

/** Get color value from color name */
export const getColorCSS = (colorName?: ColorName, intensity: Intensity = 500) =>
  colorName ? muiColors[colorName][intensity] : 'inherit'

import { Alert, Box, Link, Typography } from '@mui/material'
import React, { type FC } from 'react'

export interface AlertErrorProps {
  error: unknown

  children?: React.ReactNode
}

const parseError = (error: unknown): string => {
  if (typeof error === 'object' && error !== null && 'message' in error) {
    return error.message as string
  }
  if (typeof error === 'object' && error !== null && 'data' in error && error.data instanceof Uint8Array) {
    return new TextDecoder().decode(error.data)
  }

  if (error instanceof Error) {
    return error.stack ?? error.message
  }

  return 'An error occurred.'
}

export const AlertError: FC<AlertErrorProps> = props => {
  const { children, error } = props

  const errorMessage = parseError(error)

  return (
    <Alert severity="error">
      <Box sx={{ py: 1 }}>{children}</Box>

      <Box component="code" sx={{ py: 1 }}>
        {errorMessage}
      </Box>

      <Typography sx={{ py: 2 }}>
        This is our fault. Please reach out to us at{' '}
        <Link href="mailto:support@tunasong.com">support@tunasong.com</Link>.
      </Typography>
    </Alert>
  )
}

export default AlertError

import { zodResolver } from '@hookform/resolvers/zod'
import type { BoxProps } from '@mui/material'
import { Box, Button } from '@mui/material'
import { logger } from '@tunasong/models'
import { useCallback, useEffect } from 'react'
import type { DefaultValues, FieldErrors, FieldValues } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { FormComponentContext } from './form/component-provider.js'
import BooleanSchemaField from './form/controls/boolean.js'
import DateTimeSchemaField from './form/controls/datetime.js'
import EnumSchemaField from './form/controls/enum.js'
import ListSchemaField from './form/controls/list.js'
import LiteralSchemaField from './form/controls/literal.js'
import NumberSchemaField from './form/controls/number.js'
import ObjectSchemaField from './form/controls/object.js'
import TextSchemaField from './form/controls/text.js'
import { useSchemaForm } from './schema.hook.js'

export interface AutoFormProps<T extends FieldValues> extends Omit<BoxProps, 'onSubmit' | 'onError'> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: any // We use any to avoid deep type inference
  defaultValues?: DefaultValues<T>
  /**
   * If submitButton is specified, the form will submit on click. Otherwise, it will be submitted on every change
   * when the form is valid.
   */
  submitButton?: string
  /** Receive a callback when the submit button is clicked with a valid schema */
  onSubmit: (value: T) => void

  onError?: (errors: FieldErrors<T>) => void
}

const formComponents = {
  text: TextSchemaField,
  // eslint-disable-next-line id-blacklist
  number: NumberSchemaField,
  enum: EnumSchemaField,
  datetime: DateTimeSchemaField,
  list: ListSchemaField,
  literal: LiteralSchemaField,
  // eslint-disable-next-line id-blacklist
  boolean: BooleanSchemaField,
  object: ObjectSchemaField,
}

/** For zod validation, @see https://github.com/react-hook-form/resolvers#zod */
export function AutoForm<T extends FieldValues = FieldValues>(props: AutoFormProps<T>) {
  const { schema, defaultValues = {}, submitButton, onSubmit, onError, ...boxProps } = props

  const mode = submitButton ? 'onSubmit' : 'onChange'
  const form = useSchemaForm(schema)

  // Using explicit resolver without lazy type to reduce type inference complexity
  const {
    register,
    watch,
    handleSubmit,
    trigger,
    control,
    formState: { errors },
  } = useForm({
    mode,
    shouldFocusError: mode === 'onSubmit',
    resolver: zodResolver(schema),
    defaultValues: defaultValues as DefaultValues<T>,
  })

  const handleChange = useCallback(
    async (values: T) => {
      /** Errors is not updated here... */
      if (!(await trigger())) {
        logger.debug('AutoForm does not validate...')
        return
      }
      logger.debug('AutoForm onSubmit', values)
      onSubmit(values as T)
    },
    [onSubmit, trigger]
  )

  const handleError = useCallback(
    (errors: FieldErrors<T>) => {
      if (onError) {
        onError(errors)
      }
    },
    [onError]
  )

  /** Submit when mode is onChange and formValues has changed */
  useEffect(() => {
    if (mode !== 'onChange') {
      return
    }
    const { unsubscribe } = watch(async data => {
      handleChange(data as T)
    })

    return () => {
      unsubscribe()
    }
  }, [mode, handleChange, watch, trigger])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', ...boxProps.sx }} {...boxProps}>
      <form onSubmit={handleSubmit(handleChange, handleError)}>
        <FormComponentContext value={formComponents as never}>
          <ObjectSchemaField name="" spec={form} register={register} control={control} errors={errors} />
          {submitButton ? <Button type="submit">{submitButton}</Button> : null}
        </FormComponentContext>
      </form>
    </Box>
  )
}

export default AutoForm

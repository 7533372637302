import * as Sentry from '@sentry/react'
import { isDev, logger } from '@tunasong/models'
import { useEffect, useState } from 'react'
import { z } from 'zod'

const env = z
  .object({
    VITE_SENTRY_DSN: z.string().optional(),
  })
  .parse(import.meta.env)

export const useSentry = ({ mode, email, name }: { mode: 'light' | 'dark'; email?: string; name?: string }) => {
  const styles = {
    '#sentry-feedback': {
      '--page-margin': '40px',
    },
  }

  const [initialized, setInitialized] = useState(false)
  useEffect(() => {
    if (!env.VITE_SENTRY_DSN) {
      logger.warn('No Sentry DSN found in manifest')
      return
    }
    if (initialized) {
      return
    }

    /** @see https://docs.sentry.io/platforms/javascript/guides/react/user-feedback/ */

    // disable feedback for mobile devices since it obscures the UI
    // const feedbackIntegration = Sentry.feedbackIntegration({
    //   // Additional SDK configuration goes in here, for example:
    //   colorScheme: mode,

    //   useSentryUser: {
    //     email: 'email',
    //     name: 'name',
    //   },
    //   isNameRequired: false,
    // })

    Sentry.setUser({
      email,
      name,
    })
    Sentry.init({
      dsn: env.VITE_SENTRY_DSN,
      // @todo we get Error: Multiple Sentry Session Replay instances are not supported
      integrations: [
        Sentry.browserTracingIntegration() /*feedbackIntegration*/ /* Sentry.replayIntegration() */,
      ].filter(Boolean),

      /** @todo adjust the percentage of errors to be posted */
      tracesSampleRate: 1.0,
      /** @todo reduce to e.g., 0.1 when stable */
      replaysSessionSampleRate: 1.0, // This sets the sample rate at 100%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
    setInitialized(true)
  }, [email, initialized, name, mode])

  return {
    styles,
  }
}

import { Avatar } from '@mui/material'
import type { AvatarProps } from '@mui/material'
import type { EntityType } from '@tunasong/schemas'
import { useElementTypeMedia } from '@tunasong/plugin-lib'
import type { FC } from 'react'
import { getColorCSS } from '../color/color.js'

// Define a type for our size prop
type AvatarSize = 'small' | 'large' | 'tiny'

// Define size dimensions
const avatarSizes = {
  tiny: 3,
  small: 4,
  large: 5, // Default size for "large" avatars
}

export interface EntityAvatarProps extends Omit<AvatarProps, 'classes'> {
  className?: string
  type: EntityType
  size?: AvatarSize
}

export const EntityAvatar: FC<EntityAvatarProps> = props => {
  const { className, type, size = 'large', ...rest } = props
  const { icon: Icon, materialColor } = useElementTypeMedia(type)

  return (
    <Avatar
      sx={theme => ({
        height: theme.spacing(avatarSizes[size]),
        width: theme.spacing(avatarSizes[size]),
        ...(materialColor && { backgroundColor: getColorCSS(materialColor) }),
      })}
      className={className}
      aria-label="entity"
      {...rest}
    >
      <Icon />
    </Avatar>
  )
}

export default EntityAvatar

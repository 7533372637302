import { Button } from '@mui/material'
import type { ButtonProps } from '@mui/material'
import { Prev } from '@tunasong/icons'

import { useCallback } from 'react'
import type { FC, MouseEvent } from 'react'
import { useNavigate } from 'react-router'
import { useDimensions } from '../layout/dimensions.js'

export interface BackButtonProps extends ButtonProps {
  className?: string
}

export const BackButton: FC<BackButtonProps> = props => {
  const { className, children, ...boxProps } = props
  const { isSmallOrSmaller } = useDimensions()

  const history = window?.history

  const hasHistory = history?.length > 1

  const navigate = useNavigate()
  const navBack = useCallback(
    (ev: MouseEvent) => {
      ev.preventDefault()
      navigate(-1)
    },
    [navigate]
  )

  return (
    <Button
      sx={{
        display: 'flex',
        alignItems: 'center',
        '& :hover': {
          cursor: 'pointer',
        },
      }}
      size="small"
      aria-label="Back button"
      disabled={!hasHistory}
      onClick={navBack}
      color="inherit"
      className={className}
      {...boxProps}
    >
      <Prev color="inherit" />
      {!isSmallOrSmaller ? children : null}
    </Button>
  )
}

export default BackButton

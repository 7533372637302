import { useEffect, useRef, useState } from 'react'

/**
 * Delay value until idle or a timeout occurs. Will cancel previous invoke if called again.
 * When value is undefined, there will be no delay for the first value.
 */
export const useDelayedValue = <T>(value: T, delayMS: number) => {
  /** Clear timeout on exit */
  const handler = useRef<number | NodeJS.Timeout | null>(null)
  const [delayedValue, setDelayedValue] = useState<T>()

  useEffect(() => {
    if (handler.current) {
      clearTimeout(handler.current)
    }
    handler.current = setTimeout(() => {
      setDelayedValue(value)
      handler.current = null
    }, delayMS)
  }, [delayMS, value])

  return delayMS > 0 ? delayedValue : value
}

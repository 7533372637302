import { Card, CardHeader } from '@mui/material'
import type { FC } from 'react'
import { graphHooks } from '@tunasong/graph-lib/react'
import { UserAvatar } from './user-avatar.js'

export interface ProfileCardProps {
  userId: string
}

export const ProfileCard: FC<ProfileCardProps> = props => {
  const { userId } = props
  const { profile: user } = graphHooks.useProfile(userId)
  const title = user?.name ?? user?.email
  const subheader = user?.name ? user?.email : ''
  return (
    <Card elevation={0}>
      <CardHeader
        avatar={<UserAvatar userId={userId} sx={{ alignItems: 'normal' }} />}
        title={title}
        subheader={subheader}
      />
    </Card>
  )
}
export default ProfileCard

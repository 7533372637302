import type { InputBaseProps } from '@mui/material'
import { InputBase, Typography, useTheme } from '@mui/material'
import { graphHooks } from '@tunasong/graph-lib/react'
import { logger } from '@tunasong/models'
import type { Entity, Persisted } from '@tunasong/schemas'
import type { FC } from 'react'
import React from 'react'
import { VBox } from '../layout/vertical-box.js'
import { disableAutocomplete } from '../util/disable-autocomplete.js'
import { focusNextElement } from '../dom/focus.js'

export interface EntityTitleProps extends InputBaseProps {
  entity?: Persisted<Entity>
}

// Get the font size for the entity title. We used to have this dynamic
const getFontSize = () => '2.5rem'

export const EntityTitle: FC<EntityTitleProps> = props => {
  const { entity, ...inputProps } = props
  const updateEntity = graphHooks.useEntityUpdate({ debounceDelay: 1000 })
  const theme = useTheme()
  const inputRef = React.useRef<HTMLInputElement>(null)

  const handleTitle: React.FocusEventHandler<HTMLInputElement> = e => {
    logger.debug('EntityTitle.handleTitle', e.target.value, entity)
    if (!entity) {
      return
    }
    updateEntity(entity.id, { name: e.target.value })
  }
  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      if (inputRef.current) {
        focusNextElement(inputRef.current)
      }
    }
  }

  if (!entity) {
    return null
  }

  return (
    <VBox sx={{ alignItems: 'center' }}>
      <InputBase
        key={entity.id}
        sx={{ ...theme.typography.h1, textAlign: 'center', ...disableAutocomplete, mb: 0 }}
        placeholder={'Untitled'}
        inputRef={inputRef}
        inputProps={{ style: { fontSize: getFontSize(), textAlign: 'center' } }}
        defaultValue={entity.name ?? ''}
        fullWidth
        autoFocus={!entity.name}
        autoCorrect="off"
        onKeyDown={handleKeyDown}
        onChange={handleTitle}
        {...inputProps}
      />
      {entity.description ? <Typography variant="caption">{entity.description}</Typography> : null}
    </VBox>
  )
}

export default EntityTitle

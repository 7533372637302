import { DialogTitle as MUIDialogTitle, Typography } from '@mui/material'
import type { DialogTitleProps as MUIDialogTitleProps } from '@mui/material'
import React from 'react'
import type { MouseEvent } from 'react'
import { CloseButton } from './close-button.js'

export interface DialogTitleProps extends MUIDialogTitleProps {
  id?: string
  controls?: React.ReactNode
  children?: React.ReactNode
  onClose: (ev: MouseEvent) => void
}

export const DialogTitle = (props: DialogTitleProps) => {
  const { id, title, children, controls = null, onClose, ...restProps } = props
  return (
    <MUIDialogTitle
      id={id}
      {...restProps}
      sx={theme => ({
        margin: 0,
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        ...theme.typography.h6,
      })}
    >
      {title ? <Typography variant="h6">{title}</Typography> : null}
      {children}
      {controls}
      <CloseButton onClose={onClose} />
    </MUIDialogTitle>
  )
}

export default DialogTitle

import type { ListItemProps, Orientation } from '@mui/material'
import { Box, capitalize, IconButton, ListItem, ListItemButton, ListItemIcon } from '@mui/material'
import { Favorite, MoreVertIcon } from '@tunasong/icons'
import { dayjs, isFacade, isPersistedEntity } from '@tunasong/models'
import type { RenderMenuItemProps } from '@tunasong/plugin-lib'
import { usePlugin } from '@tunasong/plugin-lib'
import { isEntity, isPersisted } from '@tunasong/schemas'
import type { FC, MouseEvent } from 'react'
import React, { useCallback, useState } from 'react'
import { useDeviceInfo } from '../browser/device.hook.js'
import { useUIExtensions } from '../extensions.hook.js'
import { useMergeRefs } from '../hooks/merge-refs.js'
import { TunaEntityBreadcrumbs } from '../navigation/entity-breadcrumbs.js'
import { UserAvatar } from '../profile/index.js'
import { TagList } from '../tags/tag-list.js'
import { useCurrentUser } from '../user/index.js'
import { EntityIcon } from './entity-icon.js'
import { EntityListItemFacade } from './entity-list-item-facade.js'
import { StyledEntityAvatar, StyledListItemAvatar, StyledListItemText } from './list-item.styles.js'
import { usePreloadOnHover } from './preload-on-hover.js'

export interface EntityListItemProps extends RenderMenuItemProps, Omit<ListItemProps, 'classes'> {
  /** Mark the item as a favorite */
  favorite?: boolean
  hideSubtitle?: boolean
  orientation?: Orientation
  iconStyle?: 'avatar' | 'icon'
  showBreadcrumbs?: boolean
  ref?: React.Ref<HTMLLIElement>
  onClick?(ev: MouseEvent): void
}

export const EntityListItem: FC<EntityListItemProps> = props => {
  const {
    className,
    favorite,
    orientation = 'horizontal',
    element,
    size,
    hideSubtitle = false,
    iconStyle = 'icon',
    actions = true,
    showBreadcrumbs = false,
    onClick,
    ref,

    ...restProps
  } = props

  const me = useCurrentUser()

  const { isMobile } = useDeviceInfo()

  // If we have a plugin, we can show the item enabled
  const hasPlugin = Boolean(usePlugin(element))
  const disabled = props.disabled || !hasPlugin

  const [localRef, setLocalRef] = useState<HTMLLIElement | null>(null)
  const mergedRef = useMergeRefs(setLocalRef, ref)

  usePreloadOnHover({ entityId: element?.id, containerEl: localRef })

  const uiExtensions = useUIExtensions()

  // const handleRating = useCallback(
  //   (ev: SyntheticEvent<Element, Event>, rating: number | null) => {
  //     if (!isPersisted(element)) {
  //       return
  //     }
  //     ev.preventDefault()
  //     ev.stopPropagation()
  //     updateEntity(element.id, { starred: rating && rating > 0 ? true : false })
  //   },
  //   [element, updateEntity]
  // )

  const label = element.name
  const hasExternalOwner = isPersisted(element) ? element.userId !== me.userId : false
  const subtitle =
    isPersistedEntity(element) && !hideSubtitle ? (
      <>
        {capitalize(element.type)}. Updated: {dayjs(element.updatedAt).fromNow()}
        {element.description ? ' - ' + element.description : ''}
        <TagList sx={{ display: { xs: 'none', md: 'block', ml: 1 } }} tags={element.tags} size="small" />
      </>
    ) : null

  /** Actions */
  const [menuAnchorEl, setAnchorEl] = React.useState<Element | null>(null)
  const handleActionsClose = useCallback(() => setAnchorEl(null), [])
  const handleActions = useCallback((ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault()
    ev.stopPropagation()
    setAnchorEl(el => (Boolean(el) ? null : ev.currentTarget))
  }, [])

  const title = disabled ? `No plugin for ${element.type}` : undefined

  const showMenuAction = !isMobile && isPersisted(element)

  const handleContext = useCallback((ev: MouseEvent) => {
    ev.preventDefault()
    ev.stopPropagation()
    setAnchorEl(el => (Boolean(el) ? null : ev.currentTarget))
  }, [])

  if (!(isEntity(element) && isPersisted(element))) {
    return null
  }

  return isFacade(element) ? (
    <EntityListItemFacade
      className={className}
      element={element}
      {...restProps}
      ref={mergedRef as React.Ref<HTMLLIElement>}
      /** Override disabled if we have no plugin here */
      disabled={disabled}
    />
  ) : (
    <ListItem
      ref={mergedRef as React.Ref<HTMLLIElement>}
      tabIndex={0}
      className={className}
      disabled={disabled}
      sx={{
        cursor: disabled ? 'not-allowed !important' : 'pointer',
        '&.MuiListItemButton-root': {
          flexDirection: orientation === 'vertical' ? 'column' : 'row',
          flexGrow: orientation === 'vertical' ? 0 : 1,
        },
        ...restProps.sx,
      }}
      title={title}
      aria-orientation={orientation}
      onClick={disabled ? undefined : onClick}
      onContextMenu={handleContext}
      secondaryAction={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            transition: 'opacity 0.3s',
            opacity: 0.3,
            '&:hover': {
              opacity: 1,
            },
          }}
        >
          {favorite ? <Favorite color="error" titleAccess="You have this as a favorite" /> : null}
          {actions && !disabled ? (
            <>
              {/* {!element.trash && (
            <>
              <Rating
                sx={{ px: 1 }}
                name={element.id}
                value={element.starred ? 1 : 0}
                max={5}
                onChange={handleRating}
              />
            </>
          )} */}
              {hasExternalOwner && <UserAvatar userId={element.userId} size="tiny" />}
              {showMenuAction ? (
                <>
                  <IconButton tabIndex={-1} color="inherit" aria-label="settings" onClick={handleActions}>
                    <MoreVertIcon />
                  </IconButton>
                </>
              ) : null}
              {/* Expensive component so we run it on demand */}
              {menuAnchorEl && uiExtensions.entityList?.ItemMenu ? (
                <uiExtensions.entityList.ItemMenu
                  entity={element}
                  // clickAway will close the context menu on mobile
                  clickAway={!isMobile}
                  anchorEl={menuAnchorEl}
                  onClose={handleActionsClose}
                />
              ) : null}
            </>
          ) : null}
        </Box>
      }
      {...restProps}
    >
      <ListItemButton
        sx={{ padding: 0.5 }}
        // We disable ripple to allow DND previews - @see https://github.com/react-dnd/react-dnd/issues/832, otherwise the preview will contain more than the item
        disableRipple={true}
      >
        {iconStyle === 'avatar' ? (
          <StyledListItemAvatar size={size}>
            <StyledEntityAvatar type={element.type} size={size} />
          </StyledListItemAvatar>
        ) : null}
        {iconStyle === 'icon' ? (
          <ListItemIcon
            sx={{
              justifyContent: orientation === 'vertical' ? 'center' : undefined,
            }}
          >
            <EntityIcon entity={element} />
          </ListItemIcon>
        ) : null}

        <StyledListItemText
          sx={{
            paddingRight: orientation === 'vertical' ? 0 : hasExternalOwner ? 12 : 8,
          }}
          primary={
            <>
              {label}
              {showBreadcrumbs ? <TunaEntityBreadcrumbs entity={element} showHome={false} /> : null}
            </>
          }
          secondary={subtitle}
        />
      </ListItemButton>
    </ListItem>
  )
}

import type { AutocompleteProps, AutocompleteRenderInputParams } from '@mui/material'
import { Autocomplete, TextField } from '@mui/material'
import type { EntityType } from '@tunasong/schemas'
import { entityTypeNames } from '@tunasong/schemas'
import type { FC, SyntheticEvent } from 'react'

export interface EntityFilterProps extends Pick<AutocompleteProps<string, true, false, false>, 'componentsProps'> {
  className?: string
  types?: EntityType[]
  value?: EntityType[]
  onChange?(event: SyntheticEvent<Element, Event>, types: EntityType[]): void
}

export const EntityFilter: FC<EntityFilterProps> = props => {
  const { className, value = [], onChange, types = entityTypeNames, ...restProps } = props

  const handleChange = (ev: SyntheticEvent<Element, Event>, value: EntityType[]) => {
    if (!onChange) {
      return
    }

    onChange(ev, value)
  }
  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      sx={{ minWidth: 200 }}
      variant="standard"
      // @todo endAdornment screws up the CSS, so we null it here for now
      InputProps={{ ...params.InputProps, endAdornment: null }}
      placeholder="Filter..."
    />
  )

  return (
    <Autocomplete
      className={className}
      multiple
      size="small"
      options={types}
      value={value}
      onChange={handleChange}
      renderInput={renderInput}
      sx={{ minWidth: 100 }}
      {...restProps}
    />
  )
}

export default EntityFilter

import { Button, ButtonGroup, Grid, List, ListItemAvatar, ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { Functions } from '@tunasong/icons'
import { shortUuid } from '@tunasong/models'
import type { CustomCommandSpec } from '@tunasong/schemas'
import React, { useCallback } from 'react'
import type { FC, MouseEvent } from 'react'
import z from 'zod'
import { useFormDialog } from '../dialog/form-dialog.hook.js'
import { kebabCase } from '../text/kebab-case.js'
import CommandEditor from './command-editor.js'

export interface CustomCommandsProps {
  commands?: CustomCommandSpec[]

  onCommandsChange(commands: CustomCommandSpec[]): void
}

export const CustomCommands: FC<CustomCommandsProps> = props => {
  const { commands = [], onCommandsChange } = props

  const [selected, setSelected] = React.useState<CustomCommandSpec>(commands[0])

  const prompt = useFormDialog()
  const handleAdd = useCallback(
    async (ev: MouseEvent) => {
      ev.preventDefault()
      const promptResult = await prompt({
        dialogProps: { title: 'Add Custom Command' },
        schema: z.object({
          name: z.string().describe('Name of the command'),
        }),
      })
      if (!promptResult) {
        return
      }
      const newCommand: CustomCommandSpec = {
        commandId: `${kebabCase(promptResult.name)}-${shortUuid()}`,
        label: promptResult.name,
        jsCode: '',
      }
      onCommandsChange([...commands, newCommand])
    },
    [commands, onCommandsChange, prompt]
  )
  const handleUpdate = (cmd: CustomCommandSpec) => {
    onCommandsChange(commands.map(c => (c.commandId === cmd.commandId ? cmd : c)))
  }
  const handleRemove = () => {
    onCommandsChange(commands.filter(c => c.commandId !== selected.commandId))
    setSelected(commands[0])
  }

  return (
    <Grid container spacing={4}>
      <Grid size={{ xs: 4 }}>
        <List>
          {commands.map((cmd, idx) => (
            <MenuItem key={idx} selected={selected === cmd} onClick={() => setSelected(cmd)}>
              <ListItemAvatar>
                <ListItemIcon>
                  <Functions />
                </ListItemIcon>
              </ListItemAvatar>
              <ListItemText
                primary={cmd.label}
                secondary={cmd.commandId}
                secondaryTypographyProps={{ style: { overflow: 'hidden', textOverflow: 'ellipsis' } }}
              />
            </MenuItem>
          ))}
        </List>
        <ButtonGroup fullWidth>
          <Button onClick={handleAdd}>add</Button>
          <Button disabled={!selected} onClick={handleRemove}>
            remove
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid size={{ xs: 8 }}>
        <CommandEditor key={selected?.commandId} command={selected} onChange={handleUpdate} />
      </Grid>
    </Grid>
  )
}

export default CustomCommands

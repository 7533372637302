/** Device configuration settings stored in IndexedDB */

import { openDatabase } from '@tunasong/indexeddb-storage'
import { useCallback, useEffect, useMemo, useState } from 'react'

/**
 * Store a named state on a device IndexedDB database.
 *
 * The value is loaded async from IndexedDB, so if you need to wait for the value to be loaded,
 * set the `defaultValue` and wait to render until val is available.
 */
export const useDeviceState = <T>(name: string, defaultValue?: T, nameSpace = 'device') => {
  const db = useMemo(() => openDatabase<T>(nameSpace), [nameSpace])
  const [val, setVal] = useState<T | undefined>(defaultValue)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (!(db && name)) {
      return
    }
    db.get(name).then(v => {
      setVal(v ?? defaultValue)
      setReady(true)
    })
    return () => {
      setVal(undefined)
    }
  }, [db, defaultValue, name])

  const set = useCallback(
    (value: T) => {
      setVal(value)
      db?.set(name, value)
    },
    [db, name]
  )

  return [val, set, ready] as [T | undefined, typeof set, boolean]
}

import { Tab as BaseTab, tabClasses } from '@mui/base'
import type { ButtonProps } from '@mui/material'
import { Button, buttonClasses, IconButton } from '@mui/material'
import { Close } from '@tunasong/icons'
import type { FC, Ref } from 'react'

interface CustomTabProps extends Omit<ButtonProps, 'onChange'> {
  label: string
  value: string
  ref?: Ref<HTMLButtonElement>
  onClose: (value: string) => void
}

const CustomGroupTab: FC<CustomTabProps> = ({ onClose, label = 'Untitled', value, ref, ...restProps }) => (
  <Button
    ref={ref}
    LinkComponent={'a'}
    sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: theme => theme.spacing(0.5),
      cursor: 'pointer',
      fontSize: '0.875rem',
      borderRadius: 0,
      textTransform: 'none',
      backgroundColor: 'transparent',
      lineHeight: '1.2',
      border: 'none',
      justifyContent: 'center',
      [`&.${tabClasses.selected}`]: {
        fontWeight: 'bold',
        borderBottom: theme => `1px solid ${theme.vars.palette.primary.main}`,
      },
      [`&.${buttonClasses.disabled}`]: {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
    }}
    {...restProps}
  >
    {label}
    <IconButton
      onClick={ev => {
        ev.stopPropagation()
        onClose?.(value)
      }}
      sx={{ ml: 1 }}
    >
      <Close sx={{ fontSize: 12 }} />
    </IconButton>
  </Button>
)

const GroupTab = (props: CustomTabProps) => <BaseTab {...props} slots={{ root: CustomGroupTab }} />

export const ContentGroupTab: FC<CustomTabProps> = GroupTab

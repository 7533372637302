import { Box, Paper, type BoxProps } from '@mui/material'
import React from 'react'
import type { FC } from 'react'

interface HotkeyProps extends BoxProps {
  className?: string
  elevation?: number
  children: React.ReactNode
}

export const Hotkey: FC<HotkeyProps> = props => {
  const { elevation = 1, children, className } = props

  return (
    <Paper className={className} elevation={elevation} sx={{ display: 'flex', color: 'inherit' }}>
      <Box
        sx={{
          fontSize: 12,
          display: 'flex',
          alignItems: 'center',
          paddingLeft: theme => theme.spacing(),
          paddingRight: theme => theme.spacing(),
        }}
      >
        {children}
      </Box>
    </Paper>
  )
}

export default Hotkey

import type { BoxProps } from '@mui/material'
import { TableSortLabel, Typography } from '@mui/material'
import type { SortOptions } from '@tunasong/models'
import type { EntityType } from '@tunasong/schemas'
import type { FC } from 'react'
import { useCallback } from 'react'
import { HBox } from '../layout/horizontal-box.js'
import EntityFilter from './entity-filter.js'

export interface SortFilterProps extends SortOptions, Omit<BoxProps, 'order'> {
  filter?: EntityType[]
  filterTypes?: EntityType[]
  onSort(spec: SortOptions): void
  onFilter(filter: EntityType[]): void
}

export const SortFilter: FC<SortFilterProps> = props => {
  const { filterTypes, filter, sortBy, order = 'asc', type, elementOrder, onSort, onFilter, ...boxProps } = props

  const toggleDirection = order === 'asc' ? 'desc' : 'asc'

  const handleSortName = useCallback(
    () => onSort({ type: 'field', sortBy: 'name', order: toggleDirection, elementOrder }),
    [elementOrder, onSort, toggleDirection]
  )

  const handleSortType = useCallback(() => {
    onSort({ type: 'field', sortBy: 'type', order: toggleDirection, elementOrder })
  }, [elementOrder, onSort, toggleDirection])

  const handleSortUpdated = useCallback(() => {
    onSort({ type: 'field', sortBy: 'updatedAt', order: toggleDirection, elementOrder })
  }, [elementOrder, onSort, toggleDirection])

  const handleManual = useCallback(() => {
    onSort({ type: 'order', elementOrder })
  }, [elementOrder, onSort])

  // const hasFilter = (filter ?? []).length > 0
  // const hideBar = collapsed && !hasFilter

  const isManual = type === 'order'
  return (
    <HBox
      sx={{
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        opacity: 0.2,
        '&:hover': {
          opacity: 1,
          transition: 'opacity 0.2s ease-in-out',
        },
        ...boxProps.sx,
      }}
      {...boxProps}
    >
      {/* {hasFilter ? <Typography variant="caption">Filter: {(defaultFilter ?? []).join(', ')}</Typography> : null} */}

      <EntityFilter
        componentsProps={{ paper: { sx: { minWidth: 300 } } }}
        value={filter}
        types={filterTypes}
        onChange={(_, filter) => onFilter(filter)}
      />
      <TableSortLabel active={isManual} onClick={handleManual}>
        <Typography variant="caption">{isManual ? 'Manual' : 'Auto'}</Typography>
      </TableSortLabel>

      <TableSortLabel active={!isManual && sortBy === 'name'} direction={order} onClick={handleSortName}>
        <Typography variant="caption">{order === 'asc' ? 'A-Z' : 'Z-A'}</Typography>
      </TableSortLabel>
      <TableSortLabel active={!isManual && sortBy === 'type'} direction={order} onClick={handleSortType}>
        <Typography variant="caption">Type</Typography>
      </TableSortLabel>
      <TableSortLabel active={!isManual && sortBy === 'updatedAt'} direction={order} onClick={handleSortUpdated}>
        <Typography variant="caption">Updated</Typography>
      </TableSortLabel>
    </HBox>
  )
}

export default SortFilter

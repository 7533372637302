import { Box, colors, LinearProgress, Typography } from '@mui/material'
import type { BoxProps } from '@mui/material'
import { logger } from '@tunasong/models'
import type { Persisted, Entity } from '@tunasong/schemas'
import { useCallback, useEffect, useRef } from 'react'
import type { FC } from 'react'
import { useDropzone } from 'react-dropzone'
import type { Accept, FileWithPath } from 'react-dropzone'
import { isMobileDevice } from '../browser/index.js'
import { useStableRef } from '../hooks/stable-ref.js'
import { useUploadFiles } from '../upload/upload-files.hook.js'

export interface DropzoneProps extends BoxProps {
  className?: string
  parentId: string | null
  accept: Accept
  tags?: string[]
  contentHint?: string
  showZone?: boolean
  showClickZone?: boolean
  isPrivate?: boolean
  uploadOnClick?: boolean
  maxFiles?: number
  onUploading?(): void
  onUploaded?(uploaded: Persisted<Entity>): void
}

export const DropZone: FC<DropzoneProps> = props => {
  const {
    className,
    parentId,
    accept,
    children,
    showZone = false,
    uploadOnClick = false,
    isPrivate = false,
    contentHint = 'file',
    maxFiles = 50,
    title,
    showClickZone,
    onUploaded,
    onUploading,
    tags,
    ...restProps
  } = props

  const { uploadFiles, uploading, handleChange } = useUploadFiles({ isPrivate, onUploaded, tags })
  const onUploadingRef = useStableRef(onUploading)
  useEffect(() => {
    if (uploading && onUploadingRef.current) {
      onUploadingRef.current()
    }
  }, [onUploadingRef, uploading])
  const onDrop = useCallback(
    async (acceptedFiles: FileWithPath[]) => {
      uploadFiles({ files: acceptedFiles, parentId }).catch(err => {
        logger.error('Failed to upload files', err)
      })
    },
    [parentId, uploadFiles]
  )
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    maxFiles,
    accept,
    noClick: true,
  })
  const uploadRef = useRef<HTMLInputElement | null>(null)
  const handleClick = useCallback(() => {
    if (showZone || uploadOnClick) {
      uploadRef.current?.click()
    }
  }, [showZone, uploadOnClick])
  const { ...rootProps } = getRootProps()
  const acceptStr =
    Object.keys(accept).join(',') +
    Object.values(accept)
      .flatMap(v => v)
      .join(',')
  const dropZoneText = title ?? (isMobileDevice() ? 'Tap to upload' : 'Drop files or click to upload')
  const isActive = isDragActive

  return (
    <>
      <Box
        {...rootProps}
        className={className}
        sx={theme => ({
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          minHeight: 100,

          '&:focus': { outline: 'none' },
          marginBottom: theme.spacing(2),
          ...(showZone && {
            borderColor: theme.vars.palette.divider,
            borderWidth: theme.spacing(0.25),
            borderStyle: 'dashed',
          }),
          ...(isActive && {
            marginTop: theme.spacing(),
            justifyContent: 'center',
            padding: theme.spacing(2),
            borderColor: `${theme.vars.palette.primary.main} !important`,
            ...(isDragReject && { borderColor: `${theme.vars.palette.divider} !important` }),
            ...(isDragAccept && {
              borderColor: `${colors.green[500]} !important`,
              color: `${colors.green[500]} !important`,
            }),
          }),
        })}
        tabIndex={-1}
        title={uploadOnClick ? dropZoneText : undefined}
        onClick={uploadOnClick && !showClickZone ? handleClick : undefined}
        {...restProps}
      >
        <input
          {...getInputProps()}
          ref={uploadRef}
          disabled={uploading}
          type="file"
          multiple
          accept={acceptStr}
          hidden
          onChange={handleChange(parentId)}
        />
        <Typography
          variant="caption"
          sx={theme => ({
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            color: theme.vars.palette.text.secondary,
          })}
        >
          {isDragAccept && `All file(s) accepted. Drop to add ${contentHint}`}
          {isDragReject && 'Some files may be rejected'}
        </Typography>
        {!isDragActive ? <Box>{children}</Box> : null}
        {uploadOnClick && showClickZone && !isDragActive ? (
          <Box
            onClick={handleClick}
            sx={theme => ({
              display: 'flex',
              color: theme.vars.palette.action.disabled,
              flex: 1,
              cursor: 'pointer',
              justifyContent: 'center',
              alignItems: 'center',
            })}
          >
            <Typography variant="caption">{dropZoneText}</Typography>
          </Box>
        ) : null}
      </Box>
      {uploading ? <LinearProgress /> : null}
    </>
  )
}

export default DropZone

import type { DialogProps } from '@mui/material'
import { Button, DialogActions, DialogContent, Dialog as MuiDialog } from '@mui/material'
import type { FC, MouseEvent } from 'react'
import React from 'react'
import { DialogTitle } from './dialog-title.js'

export interface CDialogProps extends DialogProps {
  className?: string
  open: boolean
  controls?: React.ReactNode
  showClose?: boolean
  closeText?: string
  title?: string
  showOK?: boolean
  onClose(ev: MouseEvent): void
  onOK?(ev: MouseEvent): void
}

export const Dialog: FC<CDialogProps> = props => {
  const {
    className,
    children,
    open,
    controls = null,
    onClose,
    closeText = 'Close',
    title,
    showClose = false,
    showOK = false,
    onOK,
    ...restProps
  } = props

  if (!open) {
    return null
  }

  const showActions = showOK || showClose

  return (
    <MuiDialog
      open={open}
      className={className}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...restProps}
      slotProps={{
        paper: {
          sx: {
            ...restProps.sx,
            borderRadius: theme => theme.shape.borderRadius,
          },
          ...restProps.PaperProps,
          ...restProps.slotProps?.paper,
        },
      }}
    >
      {title ? (
        <DialogTitle onClose={onClose} id="alert-dialog-title" controls={controls}>
          {title}
        </DialogTitle>
      ) : null}
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          // padding: 0, // theme.spacing(1, 2, 1, 2),
        }}
      >
        {children}
      </DialogContent>
      {showActions ? (
        <DialogActions>
          {showOK && <Button onClick={onOK}>OK</Button>}
          {showClose && <Button onClick={onClose}>{closeText}</Button>}
        </DialogActions>
      ) : null}
    </MuiDialog>
  )
}

export default Dialog

import {
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  type ButtonProps,
  type ListItemAvatarProps,
  type ListItemProps,
  type ListItemTextProps,
} from '@mui/material'
import EntityAvatar, { type EntityAvatarProps } from './entity-avatar.js'

export interface ListItemSize {
  size?: 'tiny' | 'small' | 'normal'
}

export const StyledListItem = (props: ListItemProps) => <ListItem {...props} sx={{ padding: 0 }} />

export const StyledButton = (props: ButtonProps) => <Button {...props} sx={{ padding: 0.5, margin: 0, ...props.sx }} />

export const StyledEntityAvatar = (props: EntityAvatarProps) => <EntityAvatar {...props} sx={{ color: 'grey.100' }} />

export const StyledListItemAvatar = (props: ListItemAvatarProps & { size: 'small' | 'large' | 'tiny' | undefined }) => (
  <ListItemAvatar
    {...props}
    sx={{
      ...(props.size === 'tiny' && {
        minWidth: 4, // theme.spacing(4)
      }),
    }}
  />
)

export const StyledListItemText = (props: ListItemSize & ListItemTextProps) => (
  <ListItemText
    {...props}
    sx={{
      '& .MuiListItemText-primary': {
        fontWeight: 500,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...(props.size === 'small' && {
          fontSize: 12,
        }),
        ...(props.size === 'tiny' && {
          fontSize: 10,
        }),
      },
      '& .MuiListItemText-secondary': {
        ...(props.size === 'small' && {
          fontSize: 10,
        }),
        ...(props.size === 'tiny' && {
          fontSize: 8,
        }),
      },
    }}
  />
)

import { graphHooks } from '@tunasong/graph-lib/react'
import type { Entity, Persisted } from '@tunasong/schemas'
import type { FC } from 'react'
import AvatarList from './avatar-list.js'

export interface AccessAvatarListProps {
  entity: Persisted<Entity>
}

/**
 * Display a list of user avatars that have access to the entity.
 */
export const AccessAvatarList: FC<AccessAvatarListProps> = props => {
  const { entity } = props
  const { members } = graphHooks.useMembers(entity)
  return <AvatarList userIds={members.map(m => m.id)} />
}

export default AccessAvatarList

/** Wrapper for all the vendor services */

import type { FC, PropsWithChildren } from 'react'
import { useCrisp } from './crisp/crisp.hook.js'
import { Posthog } from './posthog/posthog.js'
import { useSentry } from './sentry/sentry.hook.js'
import { GlobalStyles, useColorScheme } from '@mui/material'

export const VendorServices: FC<PropsWithChildren> = ({ children }) => {
  const { colorScheme = 'light' } = useColorScheme()

  const { styles: sentryStyles } = useSentry({ mode: colorScheme })

  /** Crisp communication with users */
  useCrisp()

  return (
    <Posthog>
      <GlobalStyles styles={sentryStyles} />
      {children}
    </Posthog>
  )
}

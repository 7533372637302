import type { BoxProps, SxProps, Theme } from '@mui/material'
import { Avatar, Box, Typography } from '@mui/material'
import { graphHooks } from '@tunasong/graph-lib/react'
import { useSelector } from '@tunasong/redux'
import { useCallback, type PropsWithChildren } from 'react'
import { useNavigate } from 'react-router'
import { getColorCSS, getProfileColor } from '../color/index.js'

// Define size dimensions
const avatarSizes = {
  micro: 2.5,
  tiny: 3,
  small: 4,
  medium: 5,
  large: 7,
}

type AvatarSize = 'micro' | 'tiny' | 'small' | 'medium' | 'large'

export interface UserAvatarProps extends BoxProps, PropsWithChildren {
  className?: string
  userId?: string
  size?: AvatarSize

  // Show the user name in text
  showName?: boolean

  /** Active link. @default true */
  link?: boolean

  /** Use a picture of the user (if available) */
  picture?: boolean

  /** fallbackName when userId is not set - e.g., before the user is registered */
  fallbackName?: string
}

export const UserAvatar = (props: UserAvatarProps) => {
  const {
    userId,
    className,
    size = 'medium',
    showName = false,
    ref,
    link = true,
    picture = true,
    fallbackName,
    ...restProps
  } = props
  const loggedInId = useSelector(state => state.user.userId)
  const { profile } = graphHooks.useProfile(userId)
  const navigate = useNavigate()

  const { colorName } = getProfileColor(profile)

  const url = userId === loggedInId ? '/app/profile' : undefined

  const handleNavigate = useCallback(() => link && url && navigate(url), [link, navigate, url])

  const name = profile?.name ?? profile?.email ?? fallbackName
  const title = `${name} ${profile?.email ? '(' + profile.email + ')' : ''}`
  const showPicture = Boolean(profile?.picture && picture)
  const pictureUrl = showPicture && profile?.picture ? profile.picture : undefined

  const avatarSx: SxProps<Theme> = theme => ({
    '& > *': {
      margin: 0,
    },
    borderWidth: '1px !important',
    border: 'solid',
    backgroundColor: getColorCSS(colorName),
    borderColor: theme.vars.palette.divider,
    width: theme.spacing(avatarSizes[size]),
    height: theme.spacing(avatarSizes[size]),

    ...(link &&
      url && {
        '&:hover': {
          cursor: 'pointer',
        },
      }),
  })

  if (!profile) {
    return null
  }

  return (
    <Box {...restProps} display={'inline-flex'} ref={ref} onClick={handleNavigate}>
      <Avatar
        className={className}
        sx={avatarSx}
        title={title}
        alt={name}
        src={pictureUrl}
        onClick={handleNavigate}
        color="inherit"
      >
        {!showPicture && name?.[0].toUpperCase()}
      </Avatar>
      {showName ? (
        <Typography variant="caption" sx={{ ml: 1, mt: 1, mb: 2, cursor: 'pointer' }}>
          {name}
        </Typography>
      ) : null}
    </Box>
  )
}

export default UserAvatar

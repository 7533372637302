import { type SxProps, type Theme } from '@mui/material'
import type { Entity } from '@tunasong/schemas'
import { entityTypeNames, isEntity } from '@tunasong/schemas'
import { useCallback, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import invariant from 'tiny-invariant'
import type { EntityListItemProps } from '../entity/entity-list-item.js'
import { EntityListItem } from '../entity/entity-list-item.js'
import { useMergeRefs } from '../hooks/merge-refs.js'
import type { DropTarget } from './constants.js'
import DndDivider from './dnd-divider.js'

interface DraggableProps extends EntityListItemProps {
  element: Entity
  size?: 'small' | 'tiny' | 'large'

  /** -1 is top,  */
  onDropped?(source: Entity, target: DropTarget): void
  onHovering?(source: Entity, target: Entity): void
  isDropTarget(params: { source?: Entity; target: Entity }): boolean
}

export const DraggableListItem = ({ element, isDropTarget, onHovering, onDropped, ...restProps }: DraggableProps) => {
  const [{ isDragging, opacity }, dragRef, preview] = useDrag(
    () => ({
      type: element.type,
      item: element,
      collect: monitor => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
        isDragging: monitor.isDragging(),
      }),
    }),
    [element]
  )

  /** @todo this should probably be only in the DND version */
  const canDrop = useCallback((item: Entity) => Boolean(item?.id !== element?.id), [element])
  /** Send exactly one hover messsage per source, target pair */
  const [lastHover, setLastHover] = useState<{ source: Entity; target: Entity } | null>(null)
  const [{ isHovering }, dropRef] = useDrop({
    accept: entityTypeNames as never,
    drop: item => {
      if (onDropped) {
        onDropped(item, element)
      }
    },
    hover: item => {
      if (item === lastHover?.source && element === lastHover?.target) {
        return
      }
      invariant(isEntity(item), 'Item must be an Entity')
      setLastHover({ source: item, target: element })
      if (onHovering) {
        onHovering(item, element)
      }
    },
    canDrop,
    collect: monitor => ({
      isHovering: monitor.isOver({ shallow: true }), // canDrop(monitor.getItem()) &&,
    }),
  })

  const mergedRef = useMergeRefs<HTMLLIElement>(dragRef as never, dropRef as never, preview as never)

  const entityListItemSx: SxProps<Theme> = theme => ({
    ...(isDragging && {
      backgroundColor: theme.vars.palette.primary.dark,
    }),
    ...(isHovering &&
      lastHover &&
      isDropTarget(lastHover) && {
        borderColor: theme.vars.palette.success.light,
        borderWidth: 2,
        borderStyle: 'solid',
        borderRadius: theme.shape.borderRadius,
      }),
    opacity,
  })

  /**
   * @see https://react-dnd.github.io/react-dnd/examples/sortable/simple
   *
   * We have an issue where the drag preview is showing not just the item being dragged, but the entire list.
   * @see https://stackoverflow.com/questions/42234575/react-dnd-showing-entire-list-when-dragging
   *
   */

  return (
    <>
      {/* If we are hovering and we are not a drop target (e.g., folder), show a re-order divider */}
      {isHovering && lastHover && !isDropTarget(lastHover) ? <DndDivider /> : null}
      <EntityListItem {...restProps} sx={entityListItemSx} element={element} ref={mergedRef} />
    </>
  )
}

/** Use reach router navigate for now */

import type { NavigationEntity } from '@tunasong/models'
import { useNavigate } from 'react-router'
import { create } from 'zustand'
import { getEntityPath } from '../entity/entity-link-util.js'

export type NavigationMode = 'page' | 'panel' | 'drawer' | 'dialog' | 'tab' | 'external'
export interface NavigationOptions {
  layout?: string
  navigationMode?: NavigationMode
}
export type NavFn = (entity?: NavigationEntity, props?: NavigationOptions) => void

export const useNavigateToEntityStore = create<{
  customNavigateFnStack: NavFn[]
  customNavigateFn: NavFn | null
  setCustomNavigateFn: (fn: NavFn) => void
  clearCustomNavigateFn: () => void
}>(set => ({
  // We need to support nested customNavigateFn calls, so we keep the stack of customNavigateFn
  setCustomNavigateFn: (fn: NavFn) =>
    set(state => {
      const newStack = [state.customNavigateFn, ...state.customNavigateFnStack].filter(Boolean)
      return { customNavigateFn: fn, customNavigateFnStack: newStack }
    }),
  customNavigateFn: null,
  customNavigateFnStack: [],
  clearCustomNavigateFn: () =>
    set(state => {
      const [customNavigateFn, ...newStack] = state.customNavigateFnStack
      return { customNavigateFn: customNavigateFn ?? null, customNavigateFnStack: newStack }
    }),
}))

export const useNavigateToEntity = ({
  replace = false,
  alwaysUseDefaultRouter = true,
}: {
  replace?: boolean
  // alwaysUseDefaultRouter?: boolean;
  alwaysUseDefaultRouter?: boolean
} = {}): NavFn => {
  const routerNavigate = useNavigate()
  /** @todo does not use the page/panel navigation hint */
  const defaultNavigate: NavFn = (
    entity?: NavigationEntity,
    {
      layout,
      navigationMode,
    }: {
      layout?: string
      navigationMode?: NavigationMode
    } = {}
  ) => {
    if (!entity) {
      return
    }

    if (navigationMode === 'external') {
      // Open in new tab
      window.open(getEntityPath({ entity, layout }), '_blank')
      return
    }

    routerNavigate(getEntityPath({ entity, layout }), {
      replace,
    })
  }

  const { customNavigateFn } = useNavigateToEntityStore()
  if (customNavigateFn && !alwaysUseDefaultRouter) {
    return customNavigateFn
  }

  return defaultNavigate
}

export {
  BrowserRouter,
  createBrowserRouter,
  Link as NavLink,
  Route,
  RouterProvider,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router'

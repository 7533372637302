import { Box, Typography } from '@mui/material'
import type { TreeItemProps } from '@mui/x-tree-view'
import { TreeItem } from '@mui/x-tree-view'
import { Page } from '@tunasong/icons'
import type { ColorName, Entity, Persisted } from '@tunasong/schemas'
import type { FC, Ref } from 'react'
import { getColorCSS, hashColorName } from '../color/index.js'

export interface EntityTreeItemProps extends Omit<TreeItemProps, 'itemId'> {
  color?: ColorName
  Icon?: FC
  entity: Persisted<Entity>
  open?: boolean
  /** Item is excluded from selection */
  excluded?: boolean
  ref?: Ref<unknown>
}

export const EntityTreeItem = (props: EntityTreeItemProps) => {
  const {
    entity,
    ref,
    Icon = Page,
    color = entity?.type ? hashColorName(entity?.type) : 'grey',
    excluded = false,
    ...other
  } = props

  if (!entity) {
    return null
  }

  /** @todo factor out getEntityLabel, etc.  */
  const labelText = entity?.name

  return (
    <TreeItem
      itemId={entity.id}
      label={
        <Box
          ref={ref}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: theme => theme.spacing(0.5, 0),
            opacity: excluded ? 0.5 : 1,
          }}
        >
          <Icon sx={{ color: getColorCSS(color), padding: theme => theme.spacing(0, 0.5, 0, 0) }} color="inherit" />
          <Typography variant="body2">{labelText}</Typography>
        </Box>
      }
      {...other}
    />
  )
}

import { Typography, Box } from '@mui/material'
import type { Entity } from '@tunasong/schemas'
import type { Persisted } from '@tunasong/schemas'
import type { Accept } from 'react-dropzone'
import { DropZone } from '../entity/dropzone.js'

export interface UploadProps<TElement extends Entity> {
  className?: string
  parentId: string

  /** Set these tags to the uploaded entities */
  tags?: string[]

  /** Uploaded entities are private and do not inherit parent ACLs */
  isPrivate?: boolean
  accept: Accept
  contentHint?: string
  onUploaded?(uploaded: Persisted<TElement>): void
  onUploading?(): void
}

/** Upload a file to S3, and return an entity that references it  */
export function UploadStoredEntity<TElement extends Entity>(props: UploadProps<TElement>) {
  const { className, onUploaded, parentId, isPrivate = false, accept, contentHint, onUploading, tags } = props

  if (!parentId) {
    return null
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }} className={className}>
      <DropZone
        parentId={parentId}
        showZone={true}
        uploadOnClick={true}
        accept={accept}
        contentHint={contentHint}
        onUploaded={onUploaded}
        onUploading={onUploading}
        isPrivate={isPrivate}
        tags={tags}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <Typography variant="caption">Drop or click to add {contentHint}</Typography>
      </DropZone>
    </Box>
  )
}

export default UploadStoredEntity

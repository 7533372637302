import { Box } from '@mui/material'
import { SystemTags, hasSystemTag } from '@tunasong/models'
import type { BaseContentContainerProps } from '@tunasong/plugin-lib'
import type { Entity, Image, ImageElement, Persisted } from '@tunasong/schemas'
import { useRef, useState } from 'react'
import type { FC } from 'react'
import CloseButton from '../dialog/close-button.js'
import EntityTitle from '../entity/entity-title.js'
import BacklinksButton from '../graph/backlinks-button.js'
import { useMergeRefs } from '../hooks/merge-refs.js'
import { useScrollState } from '../hooks/scroll-state.js'
import type { ScrollState } from '../hooks/scroll-state.js'
import { BackgroundImage } from '../image/background-image.js'
import { HeaderImage } from '../image/header-image.js'
import ContentViews from './content-views.js'
import { TemplateHeader } from './template-header.js'
import TrashHeader from './trash-header.js'
import { graphHooks } from '@tunasong/graph-lib/react'

export interface ContentContainerProps extends BaseContentContainerProps {
  className?: string
  slots?: {
    EntityControls?: FC<{ entity?: Persisted<Entity>; onActive?: (active: boolean) => void }>
  }
  /** Show a close button, and call onClose when it's clicked */
  onClose?(): void
  /** Called when there is a change in scroll state */
  onScrollChange?(scrollState: ScrollState): void
}

export const ContentContainer: FC<ContentContainerProps> = props => {
  const {
    entity,
    large = false,
    showBacklinks = true,
    showTitle = true,
    onClose,
    slots: { EntityControls } = {},
    children,
    sx,
    contentBoxProps,
    onScrollChange,
    ...restProps
  } = props

  const { scrollRef } = useScrollState({ onScrollChange })

  const { child: backgroundImage } = graphHooks.useEntityChild<Persisted<ImageElement>>({
    parentId: entity?.id,
    filter: hasSystemTag(SystemTags.BackgroundImage),
  })

  const { child: headerImage } = graphHooks.useEntityChild<Persisted<Image>>({
    parentId: entity?.id,
    filter: hasSystemTag(SystemTags.HeaderImage),
  })
  
  const contentRef = useRef<HTMLElement | null>(null)

  const boxRef = useMergeRefs(scrollRef, contentRef)

  // Force the controls header open
  const [forceControlsOpen, setForceControlsOpen] = useState(false)

  return (
    // This is the scroll container for the contentEl
    <Box
      {...restProps}
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflow: 'auto',
        height: '100%',
        width: '100%',
        minWidth: 'min(1024px, 100%)',
        maxWidth: large ? 'fit-content' : 1024,
        containerName: 'ContentContainer',

        marginLeft: {
          xs: 0,
          sm: 'auto',
        },
        marginRight: {
          xs: 0,
          sm: 'auto',
        },

        ...sx,
      }}
    >
      <TrashHeader sx={{ flexDirection: 'row' }} entity={entity} />
      <TemplateHeader entity={entity} />
      {headerImage && <HeaderImage image={headerImage} />}
      {backgroundImage ? <BackgroundImage image={backgroundImage} /> : null}
      {entity ? (
        <>
          {showTitle ? <EntityTitle entity={entity} /> : null}
          {/* Header controls & info */}
          <Box
            sx={{
              display: { xs: 'grid' },
              gridTemplateColumns: '1fr 1fr 1fr',
              justifyContent: 'space-between',
              // hack to avoid opacity flickering
              transform: 'rotate(0)',
              opacity: forceControlsOpen ? 1 : 0.2,
              '&:hover': {
                opacity: 1,
              },

              transition: theme =>
                theme.transitions.create('opacity', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                }),
            }}
          >
            {/* Left */}
            <Box sx={{ justifySelf: 'left' }}>
              <ContentViews entity={entity} />
            </Box>
            {/* Middle */}
            <Box sx={{ justifySelf: 'center' }}>
              {EntityControls ? <EntityControls entity={entity} onActive={setForceControlsOpen} /> : null}
            </Box>
            {/* Right */}
            <Box sx={{ justifySelf: 'right' }}>
              {showBacklinks ? <BacklinksButton tabIndex={-1} id={entity.id} /> : null}
              {onClose ? <CloseButton onClose={onClose} /> : null}
            </Box>
          </Box>
        </>
      ) : null}
      <Box
        {...contentBoxProps}
        ref={boxRef}
        sx={{
          flex: 1,
          flexDirection: 'column',
          display: 'flex',
          width: '100%',
          margin: {
            xs: 0,
            sm: 'auto',
          },
          padding: theme => ({
            xs: theme.spacing(0, 1, 0, 1),
            sm: theme.spacing(0, 4, 0, 4),
          }),

          transition: theme =>
            theme.transitions.create('margin', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          ...contentBoxProps?.sx,
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default ContentContainer

import { ThemeProvider, type SupportedColorScheme } from '@mui/material/styles'
import type { FC } from 'react'
import React from 'react'
import { createTheme } from './create-theme.js'

export interface TunaThemeProps {
  colorScheme?: SupportedColorScheme
  /** @default 14 */
  textSize?: number
  children?: React.ReactNode
}

export const TunaTheme: FC<TunaThemeProps> = ({ children, textSize = 14 }) => {
  const muiTheme = createTheme({ textSize })
  return <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>
}

import { Typography, alpha, styled, keyframes, type Theme } from '@mui/material'
import type { FC } from 'react'
import { HBox } from '../layout/horizontal-box.js'

export interface PulseProps {
  size?: 'small' | 'medium' | 'large'
  placeholder?: string
}

/** from https://www.florin-pop.com/blog/2019/03/css-pulse-effect/ */

// Define the pulse animation as a keyframes object
const pulseAnimation = (theme: Theme) => keyframes`
  0% {
    transform: scale(0.8);
    box-shadow: 0 0 0 0 ${alpha(theme.palette.secondary.light, 0.7)};
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 ${theme.spacing(1)} ${alpha(theme.palette.secondary.light, 0)};
  }
  100% {
    transform: scale(0.8);
    box-shadow: 0 0 0 0 ${alpha(theme.palette.secondary.light, 0)};
  }
`

// Define the Blob component with size prop
interface BlobProps {
  size: 'small' | 'medium' | 'large'
}

const Blob = styled('div')<BlobProps>(({ theme, size }) => {
  const scale = size === 'small' ? 0.5 : size === 'medium' ? 1 : 2
  return {
    background: theme.vars.palette.secondary.main,
    boxShadow: `0 0 0 0 ${alpha(theme.palette.secondary.main, 1)}`,
    borderRadius: '50%',
    margin: theme.spacing(1),
    height: theme.spacing(2 * scale),
    width: theme.spacing(2 * scale),
    transform: 'scale(1)',
    animation: `${pulseAnimation(theme)} 2s infinite`,
  }
})

export const Pulse: FC<PulseProps> = ({ size = 'small', placeholder }) => (
  <HBox>
    <Blob size={size} />
    {placeholder ? (
      <Typography sx={{ ml: 1 }} variant="caption">
        {placeholder}
      </Typography>
    ) : null}
  </HBox>
)

export { Pulse as PulseProgress }
export default Pulse

import { Box, type BoxProps } from '@mui/material'
import { logger } from '@tunasong/models'
import type { CSSProperties, FC } from 'react'
import { useState } from 'react'
import { PulseProgressBox } from '../progress/index.js'

interface ImageProps extends BoxProps {
  url?: string
  className?: string
  rotation?: number
}

export const Image: FC<ImageProps> = props => {
  const { url, className, rotation = 0, ...boxProps } = props

  const [loaded, setLoaded] = useState(false)

  const handleLoad = () => {
    setLoaded(true)
  }

  const style: CSSProperties = className
    ? {
        transform: `rotate(${rotation}deg)`,
      }
    : {
        transform: `rotate(${rotation}deg)`,
        width: '100%',
        objectFit: 'cover',
      }

  logger.debug('Image', { url, loaded, style })
  return (
    <Box {...boxProps}>
      {url && (
        <img
          key={url}
          className={className}
          onLoad={handleLoad}
          style={style}
          src={url}
          // crossOrigin="anonymous"
          contentEditable={false}
        />
      )}

      {!loaded ? <PulseProgressBox size="small" /> : null}
    </Box>
  )
}

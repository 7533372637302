import { Box, Step, StepConnector, StepContent, StepLabel, Stepper, Typography, type BoxProps } from '@mui/material'
import { graphHooks } from '@tunasong/graph-lib/react'
import type { FeedItem } from '@tunasong/models'
import { dayjs } from '@tunasong/models'
import type { FC } from 'react'
import { UserAvatar } from '../profile/index.js'
import { useScroll } from './scroll.js'

export interface FeedProps extends BoxProps {
  className?: string
  title?: string
  items: FeedItem[]
  maxItems?: number
  topRef?: React.RefCallback<HTMLDivElement>
}

export const Feed: FC<FeedProps> = props => {
  const { className, topRef, title, maxItems = Number.MAX_SAFE_INTEGER, items = [], sx, ...boxProps } = props

  const userIds = items.map(i => i.userId)
  const { getName } = graphHooks.useProfiles({ userIds })

  const showItems = items.slice(0, maxItems)

  const { highlightId } = useScroll(items)

  return (
    <Box sx={{ overflow: 'auto', ...sx }} className={className} {...boxProps}>
      {title ? <Typography variant="subtitle1">{title}</Typography> : null}

      {showItems.length > 0 ? (
        <Stepper
          orientation="vertical"
          connector={
            <StepConnector
              sx={{
                '& .MuiStepConnector-lineVertical': {
                  minHeight: 8, // theme.spacing()
                },
              }}
            />
          }
          sx={{
            padding: 0,
            paddingLeft: 0.5, // theme.spacing(0.5)
            backgroundColor: 'inherit',
          }}
        >
          {showItems.map((item, idx) => (
            <Step
              key={idx}
              ref={idx === 0 ? topRef : null}
              active={true}
              sx={{
                ...(Boolean(item.id && item.id === highlightId) && {
                  borderColor: 'success.light',
                  borderStyle: 'dashed',
                  borderWidth: 2,
                }),
              }}
            >
              <StepLabel
                slots={{
                  stepIcon: () => (
                    <UserAvatar
                      userId={item.userId}
                      size="small"
                      sx={{
                        '& > *': {
                          margin: 0.5, // theme.spacing(0.5)
                        },
                      }}
                    />
                  ),
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="body2">{getName(item.userId)}</Typography>
                  <Typography variant="caption">{dayjs(item.createdAt).fromNow()}</Typography>
                </Box>
              </StepLabel>
              <StepContent>
                <Box sx={{ fontSize: 12 }}>{item.content}</Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      ) : (
        <Typography variant="caption">Messages will appear here - use the Chat box below</Typography>
      )}
    </Box>
  )
}

export default Feed

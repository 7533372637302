import manifest from '@tunasong/manifest'
import { logger } from '@tunasong/models'
import { Crisp as CrispSDK } from 'crisp-sdk-web'
import { useEffect } from 'react'
import { z } from 'zod'

const env = z
  .object({
    VITE_CRISP_WEBSITE_ID: z.string().optional(),
  })
  .parse(import.meta.env)

export const useCrisp = () => {
  useEffect(() => {
    if (!env.VITE_CRISP_WEBSITE_ID) {
      return
    }
    logger.debug('Crisp configured')
    CrispSDK.configure(env.VITE_CRISP_WEBSITE_ID)
  }, [])
}

import { Divider, TextField, Typography } from '@mui/material'
import type { SxProps, TextFieldProps, Theme } from '@mui/material'
import type { EntityOrElement } from '@tunasong/schemas'
import React from 'react'
import { TagList } from '../../tags/index.js'
import { capitalize } from '@tunasong/models'

export interface ElementFormProps<T> {
  className?: string
  element?: Partial<T>
  onChange(element: Partial<T>): void
  onClose?(): void
}

export const ElementForm = <T extends EntityOrElement = EntityOrElement>(props: ElementFormProps<T>) => {
  const { element, onClose, onChange } = props

  const setField =
    <K extends keyof T>(key: K) =>
    (value: T[K]) => {
      const val: Partial<T> = { [key]: value } as never
      onChange(val)
    }

  const handleKeyDown = (ev: React.KeyboardEvent) => {
    if (ev.key === 'Enter' && onClose) {
      ev.preventDefault()
      onClose()
    }
  }

  const handleTextField =
    (key: keyof T) =>
    ({ target: { value } }: { target: { value: string } }) =>
      setField(key)(value as never)

  if (!element) {
    return null
  }
  const { name, description, tags, type } = element

  const fieldSx: SxProps<Theme> = {
    marginTop: theme => theme.spacing(1),
    marginBottom: theme => theme.spacing(1),
  }
  const textFieldProps: Partial<TextFieldProps> = {
    size: 'small',
    variant: 'standard',
  }

  return (
    <>
      <Typography variant="caption">Type: {capitalize(type ?? 'Unknown')}</Typography>
      <TextField
        {...textFieldProps}
        sx={fieldSx}
        fullWidth={true}
        label="Name"
        defaultValue={name ?? ''}
        autoFocus={true}
        onKeyDown={handleKeyDown}
        onChange={handleTextField('name')}
      />
      <TextField
        {...textFieldProps}
        sx={fieldSx}
        fullWidth={true}
        label="Description"
        multiline={true}
        defaultValue={description ?? ''}
        onChange={handleTextField('description')}
      />
      <Divider>
        <Typography variant="caption">Tags</Typography>
      </Divider>
      <TagList tags={tags} onChange={setField('tags')} />
    </>
  )
}

export default ElementForm

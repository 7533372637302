import { Typography } from '@mui/material'
import { TreeItem as MuiTreeItem, SimpleTreeView } from '@mui/x-tree-view'
import { graphHooks } from '@tunasong/graph-lib/react'
import { ArrowDropDown, ArrowRight } from '@tunasong/icons'
import type { FilterFunc } from '@tunasong/models'
import { folderSort, isTaggedSysEntity, isTopLevelEntity } from '@tunasong/models'
import { getElementMedia, usePlugins } from '@tunasong/plugin-lib'
import type { Entity, Persisted } from '@tunasong/schemas'
import type { FC } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { EMPTY_NODE, LOADING_NODE } from './constants.js'
import type { EntityTreeItemProps } from './entity-tree-item.js'
import { EntityTreeItem } from './entity-tree-item.js'

export interface EntityTreeProps {
  /**  */
  includeRoot?: boolean
  rootEntities: Persisted<Entity>[]
  /** @default show all top-level entities */
  filter?: FilterFunc
  /** Entity Ids that should not be selectable */
  disableIds?: string[]
  /** Selected item */
  selected: Persisted<Entity> | undefined
  TreeItem?: FC<EntityTreeItemProps>
  onSelect(entity?: Persisted<Entity>): void
  onSelectRoot?(): void
}

export const EntityTree: FC<EntityTreeProps> = props => {
  const {
    includeRoot = true,
    rootEntities,
    filter = isTopLevelEntity,
    selected,
    onSelectRoot,
    onSelect,
    TreeItem = EntityTreeItem,
    disableIds,
    ...restProps
  } = props

  const [expanded, setExpanded] = useState<string[]>(['ROOT'])

  /** If filter is specified, load all child entities and filter using that function. Otherwise, filter on type */
  const filterType = props.filter ? undefined : 'folder'

  const plugins = usePlugins('all')
  // We need to track the loaded children to ensure that RTK Query does not release the cache
  const [entityChildren, setEntityChildren] = useState<Record<string, Persisted<Entity>[]>>({
    ROOT: rootEntities,
  })

  /** We use the result here to ensure we refresh the UI on changes */
  const [loadChildEntities] = graphHooks.useLoadChildEntities()

  const [loadEntity] = graphHooks.useLoadEntity()
  const childEntities = graphHooks.useGetEntityChildren()

  const getEntityChildren = useCallback(
    (parentId: string) => childEntities({ parentId, type: filterType }),
    [childEntities, filterType]
  )

  const getOrLoadChildren = useCallback(
    async (id: string) => {
      if (entityChildren[id]) {
        return entityChildren[id]
      }
      const { data: children } = await loadChildEntities({ parentId: id, type: filterType }, true)
      if (children) {
        setEntityChildren({
          ...entityChildren,
          [id]: children,
        })
      }
      return children
    },
    [entityChildren, filterType, loadChildEntities]
  )

  /** Ensure that all parents of selected item are expanded */
  const ancestors = graphHooks.useAncestors(selected)
  const allExpanded = [
    ...new Set([
      ...expanded,
      /* selected?.id, */ ...Object.values(ancestors)
        .map(e => e.id)
        .filter(Boolean),
    ]),
  ]
  /** Always load the root, expanded, and disabledIDs. We need to load disabled because expanding will not be possible  */
  const initialLoad = useRef(false)
  useEffect(() => {
    if (initialLoad.current || rootEntities.length === 0) {
      return
    }
    const rootIds = [...rootEntities.map(e => e.id), ...(disableIds ?? [])]
    for (const nodeId of rootIds) {
      getOrLoadChildren(nodeId)
    }
    initialLoad.current = true
  }, [disableIds, getOrLoadChildren, loadChildEntities, rootEntities])

  const getChildrenElements = useCallback(
    (entity: Persisted<Entity>) => {
      /** @note to ensure that we re-render when we get new data, we add a (fake) dependency on loadChildEntitiesResult */
      /** @todo does this use the current data? */
      const { data: entityChildren = [] } = getEntityChildren(entity.id)
      if (entityChildren.length === 0) {
        /** @note if children is null then the tree will not have an expand button */
        return null // isLoading ? <EntityTreeItemLoading /> : null
      }

      const children = entityChildren
        .filter(e => e.parentId === entity.id)
        .filter(e => !isTaggedSysEntity(e))
        .filter(filter)
        .sort()
      const e = folderSort(children.filter(filter))
      return e.map(child => {
        const { icon, materialColor } = getElementMedia(child.type, plugins)
        return (
          <TreeItem
            key={child.id}
            entity={child}
            /** We open up for disabled items */
            open={disableIds?.includes(child.id) || child === selected}
            excluded={disableIds?.includes(child.id)}
            Icon={icon}
            color={materialColor}
          >
            {getChildrenElements(child)}
          </TreeItem>
        )
      })
    },
    [TreeItem, disableIds, filter, getEntityChildren, plugins, selected]
  )

  const handleToggle = async (ev: unknown, itemId: string) => {
    if (!itemId) {
      return
    }
    if (expanded.some(i => i === itemId)) {
      setExpanded(expanded.filter(id => id !== itemId))
      return
    }
    const allExpanded = [...new Set([...expanded, itemId])].filter(Boolean)
    setExpanded(allExpanded)
    for (const nodeId of allExpanded) {
      if (nodeId === 'ROOT') {
        continue
      }
      /** @todo do we heed to handle derivied types, e.g., bandspace? This will always be level 2 and below */
      const children = await getOrLoadChildren(nodeId)

      /** Load the children of the children as well */
      for (const child of children ?? []) {
        getOrLoadChildren(child.id)
      }
    }
  }

  const handleSelect = useCallback(
    async (ev: unknown, itemId: string | null) => {
      /** Loading nodes have  */
      const ignoreNodes = [LOADING_NODE, EMPTY_NODE, ...(disableIds ?? [])]
      if (itemId === 'ROOT' && onSelectRoot) {
        onSelectRoot()
        return
      }
      if (onSelect && itemId) {
        const { data: entity } = await loadEntity({ id: itemId }, true)
        onSelect(ignoreNodes.includes(itemId) ? undefined : entity)
      }
    },
    [disableIds, loadEntity, onSelect, onSelectRoot]
  )

  /** @note we don't memo the tree-items because we can receive new data at any time  */

  const items = (
    <>
      {includeRoot && (
        <MuiTreeItem key={'root'} itemId={'ROOT'} label={<Typography variant="subtitle1">Top</Typography>} />
      )}
      {folderSort(rootEntities.filter(filter)).map(entity => {
        const { icon, materialColor: color } = getElementMedia(entity.type, plugins)
        return (
          <TreeItem
            key={entity.id}
            entity={entity}
            open={Boolean(disableIds?.includes(entity.id) || (entity && allExpanded.find(id => id === entity.id)))}
            excluded={disableIds?.includes(entity.id)}
            Icon={icon}
            color={color}
          >
            {getChildrenElements(entity)}
          </TreeItem>
        )
      })}
    </>
  )

  return (
    <DndProvider backend={HTML5Backend}>
      <SimpleTreeView
        onItemSelectionToggle={handleToggle}
        onItemExpansionToggle={handleToggle}
        onSelectedItemsChange={handleSelect}
        expandedItems={expanded}
        selectedItems={selected?.id ?? ''}
        slots={{
          collapseIcon: ArrowDropDown,
          expandIcon: ArrowRight,
        }}
        /** we don't use multiselect yet */
        {...restProps}
      >
        {items}
      </SimpleTreeView>
    </DndProvider>
  )
}

export default EntityTree

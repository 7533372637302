import Splitter, { SplitDirection } from '@devbookhq/splitter'
import { Box, styled } from '@mui/material'
import type { BoxProps } from '@mui/material'
import React, { useCallback } from 'react'
import type { Ref } from 'react'

export interface VericalSplitterProps extends Omit<BoxProps, 'left' | 'right' | 'onResize'> {
  left: React.ReactNode
  /** Specify flex as the percentage the left pane should occupy. */
  defaultLeftPercentage?: number
  minWidths?: [number, number]
  right: React.ReactNode
  thickness?: number
  ref?: Ref<HTMLDivElement>
  /** onResize will return the leftFlex */
  onResize?(left: number): void
}

const SplitterGutterStyle = styled('div')(({ theme }) => ({
  backgroundColor: theme.vars.palette.divider,
  width: theme.spacing(0.8),
}))

const SplitterDraggerStyle = styled('div')(({ theme }) => ({
  backgroundColor: theme.vars.palette.divider,
}))

const ContentPane = styled('div')({
  display: 'flex',
  flex: 1,
  height: '100%',
  flexDirection: 'column',
  overflow: 'auto',
})

export const VerticalSplitter = (props: VericalSplitterProps) => {
  const { left, right, ref, minWidths = [50, 50], defaultLeftPercentage = 50, onResize, ...restProps } = props

  const [leftFlex, setLeftFlex] = React.useState(defaultLeftPercentage ?? 50)

  const onResizePane = useCallback(
    (pairIdx: number, newSizes: number[]) => {
      if (pairIdx !== 0) {
        return
      }
      const flex = newSizes[0]
      setLeftFlex(flex)
      if (onResize) {
        onResize(flex)
      }
    },
    [onResize]
  )

  return (
    <Box ref={ref} {...restProps} sx={{ ...restProps.sx, height: '100%' }}>
      <Splitter
        direction={SplitDirection.Horizontal}
        minWidths={minWidths}
        initialSizes={[leftFlex, 100 - leftFlex]}
        onResizeFinished={onResizePane}
        gutterClassName={SplitterGutterStyle.toString()}
        draggerClassName={SplitterDraggerStyle.toString()}
      >
        <ContentPane>{left}</ContentPane>
        <ContentPane>{right}</ContentPane>
      </Splitter>
    </Box>
  )
}

export default VerticalSplitter

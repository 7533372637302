// eslint-disable-next-line spaced-comment
///  <reference types="vite/client" />
import { PostHogProvider } from 'posthog-js/react'
import type React from 'react'
import { z } from 'zod'

const env = z
  .object({
    VITE_PUBLIC_POSTHOG_KEY: z.string().optional(),
    VITE_PUBLIC_POSTHOG_HOST: z.string().optional(),
    VITE_PUBLIC_POSTHOG_UI_HOST: z.string().optional(),
  })
  .parse(import.meta.env)

export const Posthog = ({ children }: { children: React.ReactNode }) => {
  if (!env.VITE_PUBLIC_POSTHOG_KEY) {
    return <>{children}</>
  }
  return (
    <PostHogProvider
      apiKey={env.VITE_PUBLIC_POSTHOG_KEY}
      options={{
        api_host: env.VITE_PUBLIC_POSTHOG_HOST,
        ui_host: env.VITE_PUBLIC_POSTHOG_UI_HOST,
      }}
    >
      {children}
    </PostHogProvider>
  )
}

/** Content Security Policy */
import { vendorsCSP } from '@tunasong/vendor'
import type { FC } from 'react'
import { Helmet } from 'react-helmet-async'

export interface CSPProps {
  domainName?: string
}

/**
 * @todo if we want to incrementally add to the CSP in e.g., plugins we need some sort of global state
 */
export interface CSPProps {
  domainName?: string
  previewDomainName?: string
  connectSrc?: string[]
  frameSrc?: string[]
  mediaSrc?: string[]
  styleSrc?: string[]
  fontSrc?: string[]
  scriptSrc?: string[]
}

export const CSP: FC<CSPProps> = ({
  domainName = 'tunasong.com',
  previewDomainName = 'preview.tunasong.com',
  connectSrc = vendorsCSP.connectSrc,
  mediaSrc = vendorsCSP.mediaSrc,
  frameSrc = vendorsCSP.frameSrc,
  styleSrc = vendorsCSP.styleSrc,
  fontSrc = vendorsCSP.fontSrc,
  scriptSrc = vendorsCSP.scriptSrc,
}) => {
  const csp = {
    'default-src': `'self' blob: *.${domainName} *.${previewDomainName}`,
    'script-src': `'self' blob: *.${domainName} ${scriptSrc.join(' ')}`,
    'style-src': `'self' 'unsafe-inline' ${styleSrc.join(' ')}`,
    'worker-src': `'self' blob:`,
    'child-src': `'self' blob: ${domainName} *.${domainName} ${previewDomainName} *.${previewDomainName}`,
    'media-src': `'self' blob: data: ${domainName} *.${previewDomainName} ${mediaSrc.join(' ')}`,
    'img-src': `* blob: data: ${domainName} *.${previewDomainName} www.google-analytics.com`,
    'font-src': `'self' data: ${domainName} *.${domainName} *.${previewDomainName} ${fontSrc.join(' ')}`,
    'connect-src': `'self' *.lambda-url.eu-west-1.on.aws blob: ${domainName} *.${domainName} *.${previewDomainName} wss://ws.api.${domainName} ${connectSrc.join(
      ' '
    )}`,
    'frame-src': `'self' ${domainName}  *.${previewDomainName} ${frameSrc.join(' ')}`,
  }

  const cspContent = Object.entries(csp)
    .map(([key, value]) => `${key} ${value} ;`)
    .join('\n')

  return (
    // Will overwrite the existing CSP key
    <Helmet>
      <meta http-equiv="Content-Security-Policy" content={cspContent} />
    </Helmet>
  )
}

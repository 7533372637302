import { Page } from '@tunasong/icons'
import { useElementTypeMedia } from '@tunasong/plugin-lib'
import type { Entity } from '@tunasong/schemas'
import type { FC } from 'react'
import { getColorCSS } from '../color/color.js'

interface EntityIconProps {
  entity: Pick<Entity, 'type'>
  size?: 'small' | 'medium' | 'large'
  DefaultIcon?: FC<{ className?: string }>
}

export const EntityIcon: FC<EntityIconProps> = props => {
  const { entity, size = 'medium', DefaultIcon = Page } = props
  const { icon: Icon, materialColor: color } = useElementTypeMedia(entity.type)

  return entity ? (
    <Icon
      sx={{
        fontSize: size === 'small' ? 16 : size === 'medium' ? 24 : 32,
        color: getColorCSS(color),
      }}
    />
  ) : (
    <DefaultIcon />
  )
}

import { Box, Chip, InputBase } from '@mui/material'
import type { BoxProps } from '@mui/system'
import type { ChangeEvent, FC, KeyboardEvent } from 'react'
import { useState } from 'react'
import { getColorCSS, hashColorName } from '../color/index.js'
import { disableAutocomplete } from '../util/index.js'

export interface TagListProps extends Omit<BoxProps, 'onChange'> {
  className?: string
  tags?: string[]
  fullWidth?: boolean
  placeholder?: string
  variant?: 'outlined' | 'filled'
  size?: 'small' | 'medium'
  // If onChange is provided, the input field will be displayed
  onChange?(tags: string[]): void
}

export const TagList: FC<TagListProps> = props => {
  const {
    className,
    tags: defaultTags = [],
    size = 'medium',
    onChange,
    variant = 'outlined',
    fullWidth = true,
    placeholder = 'Add tag...',
    ...restProps
  } = props
  const [name, setName] = useState('')

  const tags = defaultTags ?? []

  const handleDelete = (tag: string) => () => onChange && onChange(tags.filter(el => el !== tag))
  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => setName(ev.target.value)
  const handleReturn = (ev: KeyboardEvent<HTMLInputElement>) => {
    const val: string = ev.currentTarget.value
    if (ev.key === 'Enter' && val) {
      setName('')
      if (tags.includes(val)) {
        return
      }
      if (onChange) {
        onChange([...tags, val.toLowerCase()])
      }
    }
  }

  return (
    <Box
      component={'span'}
      className={className}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        fontSize: theme => theme.typography.fontSize * 0.8,
      }}
      {...restProps}
    >
      {tags.map(c => (
        <Chip
          component={'span'}
          sx={{
            margin: theme => theme.spacing(1),
            marginLeft: 0,
            color: getColorCSS(hashColorName(c)),
          }}
          key={c}
          label={c}
          size={size}
          variant={variant}
          onDelete={onChange ? handleDelete(c) : undefined}
        />
      ))}
      {onChange && (
        <InputBase
          id="tag-list"
          sx={{
            color: theme => theme.vars.palette.grey[500],
            fontSize: 13,
            whiteSpace: 'nowrap',
          }}
          fullWidth={fullWidth}
          value={name}
          placeholder={placeholder}
          onKeyUp={handleReturn}
          onChange={handleChange}
          inputProps={disableAutocomplete}
        />
      )}
    </Box>
  )
}

export default TagList

import { styled } from '@mui/material'
import type { FC, RefObject } from 'react'
import React, { useRef } from 'react'
import ReactDraggable from 'react-draggable'

export interface DraggableProps {
  className?: string
  children: React.ReactNode
}

const Container = styled('div')({
  display: 'flex',
})

const Handle = styled('div')({
  cursor: 'grab',
  display: 'flex',
  flex: 1,
})

export const Draggable: FC<DraggableProps> = props => {
  const { className, children } = props
  const handleMouse = (ev: globalThis.MouseEvent) => ev.preventDefault()
  const containerRef = useRef<HTMLDivElement | null>(null)

  return (
    <ReactDraggable handle="div" nodeRef={containerRef as RefObject<HTMLElement>} onMouseDown={handleMouse}>
      <Container className={className} ref={containerRef}>
        <Handle>{children}</Handle>
      </Container>
    </ReactDraggable>
  )
}

export default Draggable

export const vendorsCSP = {
  connectSrc: [
    'tfhub.dev',
    'storage.googleapis.com',
    'tunasong-storage.s3.eu-west-1.amazonaws.com',
    'https://api.openai.com',
    'https://o526050.ingest.sentry.io',
    'https://sentry.io',
    'wss://client.relay.crisp.chat',
    'https://client.crisp.chat',
  ],
  scriptSrc: [
    'https://o526050.ingest.sentry.io',
    'https://sentry.io/api/embed/error-page',
    'https://client.crisp.chat',
  ],
  mediaSrc: ['tunasong-storage.s3.eu-west-1.amazonaws.com'],
  frameSrc: ['*.signatu.com', '*.spotify.com', '*.spotifycdn.com', '*.youtube.com', '*.soundcloud.com'],
  styleSrc: ['fonts.googleapis.com', 'https://client.crisp.chat'],
  fontSrc: ['fonts.gstatic.com', 'https://client.crisp.chat'],
}

import type { BreadcrumbsProps, TypographyVariant } from '@mui/material'
import { Breadcrumbs, Link, Typography } from '@mui/material'
import { Home } from '@tunasong/icons'
import type { NavigationEntityWithType } from '@tunasong/models'
import { getElementMedia, usePlugins } from '@tunasong/plugin-lib'
import type { FC } from 'react'
import { Fragment, useMemo, useState } from 'react'
import { Link as RouterLink } from 'react-router'
import { getEntityPath } from '../entity/entity-link-util.js'
import { useContainerSize } from '../hooks/index.js'

export interface TunaBreadcrumbsProps extends BreadcrumbsProps {
  className?: string
  parentChain: NavigationEntityWithType[]
  variant?: TypographyVariant
  icon?: boolean
  /** Show the Home icon. @default true */
  showHome?: boolean
  /** Show the leaf - i.e., the entity. @default true */
  showLeaf?: boolean
  /** Make the links active, i.e., clickable. @default true */
  activeLinks?: boolean
  back?: boolean
}

export const TunaBreadcrumbs: FC<TunaBreadcrumbsProps> = props => {
  const {
    className,
    parentChain = [],
    activeLinks = true,
    variant = 'subtitle1',
    icon = true,
    showHome = true,
    showLeaf = true,
    maxItems = -1,
  } = props
  const plugins = usePlugins('all')

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const size = useContainerSize(anchorEl)

  const linkProps = useMemo(
    () => ({
      variant,
      color: 'inherit',
      sx: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 1,
        fontWeight: 300,
        textDecoration: 'none',
        '&:hover': {
          cursor: 'pointer',
          textDecoration: 'underline',
        },
      },
    }),
    [variant]
  )

  const path = useMemo(() => {
    const p = [...parentChain]
    const trimmed = showLeaf ? p : p.slice(0, p.length - 1)
    return trimmed
  }, [parentChain, showLeaf])

  const links = useMemo(
    () =>
      path.map((e, idx) => {
        const { colorCode, icon: Icon } = getElementMedia(e.type, plugins)
        const content = (
          <Fragment>
            {icon ? <Icon sx={{ marginRight: 0.5, width: 20, height: 20, color: colorCode }} /> : null}
            {e.name ? e.name : 'Untitled'}
          </Fragment>
        )
        return activeLinks ? (
          <Link
            key={idx}
            component={RouterLink}
            {...linkProps}
            to={getEntityPath({ entity: e })}
            sx={{ ...linkProps.sx, fontSize: 14 }}
          >
            {content}
          </Link>
        ) : (
          <Typography
            variant={variant}
            color="inherit"
            key={idx}
            sx={{ display: 'flex', alignItems: 'center', marginRight: 1 }}
          >
            {content}
          </Typography>
        )
      }),
    [activeLinks, icon, linkProps, path, plugins, variant]
  )

  const pathLen = path.length + (showHome ? 1 : 0)
  const maxTotalItems = size && maxItems === -1 ? Math.max(Math.ceil(size.width / 175), 2) : Math.max(maxItems, 3)
  const itemsBeforeCollapse = maxTotalItems < pathLen + 1 ? 1 : undefined
  const itemsAfterCollapse = maxTotalItems < pathLen ? Math.max(1, maxTotalItems - 2) : undefined

  return (
    <Breadcrumbs
      ref={setAnchorEl}
      className={className}
      aria-label="breadcrumb"
      itemsBeforeCollapse={itemsBeforeCollapse}
      itemsAfterCollapse={itemsAfterCollapse}
      maxItems={maxTotalItems}
      sx={{ margin: 0, ...props.sx }}
    >
      {showHome ? (
        <Link
          sx={{ ...linkProps.sx, marginRight: '0px !important', color: theme => theme.vars.palette.action.disabled }}
          component={RouterLink}
          variant={variant}
          color="inherit"
          to="/app"
          title="Home link"
          aria-label="Home link"
        >
          {icon ? <Home sx={{ marginRight: '0px !important', width: 24, height: 24 }} color="inherit" /> : null}
        </Link>
      ) : null}
      {links}
    </Breadcrumbs>
  )
}

import { TextField } from '@mui/material'
import type { FC } from 'react'
import type { FormComponentProps } from '../form-component.js'

export const NumberSchemaField: FC<FormComponentProps> = props => {
  const { name, spec, register, ...restProps } = props
  const setValueAs = (v?: string) => (v ? parseInt(v, 10) : undefined)
  return (
    <TextField
      type="number"
      label={spec.metadata?.label ?? spec.name}
      inputProps={{ ...register(name, { setValueAs }) }}
      {...restProps}
    />
  )
}

export default NumberSchemaField

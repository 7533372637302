function getFocusableElements(): HTMLElement[] {
  const focusableSelectors = [
    'a[href]',
    'button:not([disabled])',
    'textarea:not([disabled])',
    'input:not([disabled])',
    'select:not([disabled])',
    '[role="textbox"]',
    '[tabindex="0"]',
  ]
  return Array.from(document.querySelectorAll(focusableSelectors.join(','))).filter(
    // Remove the elements with tabindex="-1"
    e => e.getAttribute('tabindex') !== '-1'
  ) as HTMLElement[]
}

export function focusNextElement(current: HTMLElement): void {
  const focusable = getFocusableElements()
  const index = focusable.indexOf(current)
  if (index >= 0 && index < focusable.length - 1) {
    focusable[index + 1].focus()
  }
}

import { Box } from '@mui/material'
import type { ImageElement, Persisted } from '@tunasong/schemas'
import React from 'react'
import type { FC } from 'react'

interface BackgroundImageProps {
  image: Persisted<ImageElement>
}

// Base styles as a reusable object
const baseStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  height: '100vh',
  width: '100vw',
};

export const BackgroundImage: FC<BackgroundImageProps> = props => {
  const { image } = props
  const url = image.storageUrls?.sizes?.large
  
  return (
    <>
      <img 
        src={url}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100vh',
          width: '100vw',
          objectFit: 'cover',
          border: 'none',
        } as React.CSSProperties} 
      />
      <Box 
        sx={(theme) => ({
          ...baseStyles,
          backgroundColor: `rgba(${theme.vars.palette.background.defaultChannel} / 0.8)`,
        })}
      />
    </>
  )
}

import { Typography } from '@mui/material'
import type { FC } from 'react'
import { Hotkey } from './hotkey.js'
import { makeLabel } from './make-label.js'
import type { Shortcut } from './shortcut.js'

interface ShortcutBoxProps {
  className?: string
  shortcut: Shortcut
  showDescription?: boolean
}

const ShortcutBox: FC<ShortcutBoxProps> = props => {
  const { className, shortcut, showDescription = true } = props
  if (!shortcut) {
    return null
  }
  const { description } = shortcut
  return (
    <div
      className={className}
      style={{
        display: 'inline-flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',
      }}
    >
      <Hotkey>{makeLabel(shortcut.hotkey)}</Hotkey>
      {showDescription && (
        <Typography variant="subtitle1" sx={{ marginLeft: theme => theme.spacing() }}>
          {description}
        </Typography>
      )}
    </div>
  )
}

export default ShortcutBox

import { ListItemAvatar } from '@mui/material'
import { isFacade } from '@tunasong/models'
import type { RenderMenuItemProps } from '@tunasong/plugin-lib'
import type { FC } from 'react'
import { StyledEntityAvatar, StyledListItem, StyledListItemText } from './list-item.styles.js'

/** Facade for a inaccessible item */
interface EntityListItemFacadeProps extends RenderMenuItemProps {
  ref: React.Ref<HTMLLIElement>
}
export const EntityListItemFacade: FC<EntityListItemFacadeProps> = props => {
  const { element, size, ref, ...restProps } = props

  if (!isFacade(element)) {
    return null
  }

  return (
    <StyledListItem component={'div'} {...restProps} ref={ref}>
      <ListItemAvatar>
        <StyledEntityAvatar type={element.type} size={size} />
      </ListItemAvatar>

      <StyledListItemText primary={element.description} secondary={element.id} />
    </StyledListItem>
  )
}

export default EntityListItemFacade
